<div class="d-flex justify-content-center">
    <div class="spinner-grow spinner-grow-sm text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow spinner-grow-sm text-accent-1" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow spinner-grow-sm text-accent-2" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>