import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MENU } from 'src/app/constants';
import { NightwebUtil } from 'src/app/utils';
import { TableConfig } from '../../common/interface';

@Component({
  selector: 'app-assign-permission-modal',
  templateUrl: './assign-permission-modal.component.html',
  styleUrls: ['./assign-permission-modal.component.scss']
})
export class AssignPermissionModalComponent implements OnInit {

  assignPermissionForm!: FormGroup;

  loading = false;
  submitted = false;
  title!: string;
  formData: any = {};
  confirmButtonLabel: string = 'Update';
  cancelButtonLabel: string = 'Cancel';

  tableConfig: TableConfig = {
    headerElements: [
      'Permission', 'Read', 'Write'
    ],
    bodyElements: [
      { value: 'createdAt', dataType: 'dateTime' },
      { value: 'name', dataType: 'object', objectKey: 'createdBy' },
      { value: 'type', dataType: 'object', objectKey: 'event' },
      { value: 'associatedPhone', dataType: 'phone' },
      { value: 'note', dataType: 'object', objectKey: 'log' },
    ],
    tableDatas: [],
    actionItems: [
    ]
  }
  tableHeadClass: string = 'bg-gray-light text-gray-dark';

  menus = MENU;

  accessibleMenus: any[] = [];

  constructor(
    private nightwebUtil: NightwebUtil,
    public modalRef: MdbModalRef<AssignPermissionModalComponent>
  ) { }

  ngOnInit(): void {
    if (this.formData?.permissions.length) {
      this.accessibleMenus = this.formData.permissions.filter((menu: any) => {
        if (this.nightwebUtil.isAccessible(menu.resourceName)) {
          return {
            ...menu
          };
        }
      });
    }
  }

  confirm() {
    this.modalRef.close(this.formData)
  }

  close() {
    this.submitted = false;
    this.modalRef.close();
  }

  update() {

  }

  cancel() {
    this.modalRef.close();

  }

  receiveEvent(event: any) {

  }

}
