import { Component, OnInit } from '@angular/core';
import { MdbNotificationRef } from 'mdb-angular-ui-kit/notification';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent implements OnInit {
  title!: string;
  message!: string;
  toasterClass = 'bg-'
  constructor(public notificationRef: MdbNotificationRef<ToasterComponent>) { }

  ngOnInit(): void {
    this.toasterClass += this.title.toLowerCase() === 'success' ? 'success' : (this.title.toLowerCase() === 'warning' ? 'warning' : 'danger');
  }

}
