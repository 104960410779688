import { Routes } from "@angular/router";

import * as components from '../components'

export const RoutePublic: Routes = [
  {
    path: 'login',
    component: components.LayoutPublicComponent,
    children: [{ path: '', component: components.LoginComponent }]
  },
  {
    path: 'logout',
    component: components.LogoutComponent
  },
  {
    path: 'admins/verify',
    component: components.ResetPasswordComponent
  },
  {
    path: 'reset-password',
    component: components.LayoutPublicComponent,
    children:  [{ path: '', component: components.ResetPasswordComponent }] 
  },
];