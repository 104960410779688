import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { API_CONSTANTS, MESSAGE_CONSTANTS, STATUS_CODES } from '../constants';
import { NightwebUtil } from '../utils';
import { HttpClientService } from './http-client.service';
import { Router } from '@angular/router';
import { ToasterService } from './toaster.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user!: any;

  constructor(
    private router: Router,
    private http: HttpClientService,
    private nightwebUtil: NightwebUtil,
    private toasterService: ToasterService
  ) { }

  public isAuthenticated(): boolean {
    this.user = this.nightwebUtil.getLoggedInUser();
    if (this.user) {
      return true;
    } else {
      return false;
    }
  }

  public isRoleAuthorized(permission: any): boolean {
    return true;
  }

  login(credentials: any) {
    return this.http.post(`${API_CONSTANTS.AUTH}${API_CONSTANTS.LOGIN}`, credentials).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  register(body: any) {
    return this.http.post(`${API_CONSTANTS.AUTH}${API_CONSTANTS.REGISTER}`, body).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  verifyLoginCode(data: any) {
    return this.http.post(`${API_CONSTANTS.AUTH}${API_CONSTANTS.VERIFY_CODE}`, data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  resendLoginCode(username: string) {
    return this.http.post(`${API_CONSTANTS.AUTH}${API_CONSTANTS.RESEND_CODE}`, {
      email: username
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  forgotPassword(username: string) {
    return this.http.post(`${API_CONSTANTS.AUTH}${API_CONSTANTS.FORGOT_PASSWORD}`, username).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  changePassword(data: any) {
    return this.http.patch(`${API_CONSTANTS.AUTH}${API_CONSTANTS.CHANGE_PASSWORD}`, data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  resetPassword(body: any) {
    return this.http.post(`${API_CONSTANTS.AUTH}${API_CONSTANTS.SET_PASSWORD}`, body).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  refresh() {
    return this.http.get(`${API_CONSTANTS.AUTH}${API_CONSTANTS.REFRESH}`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  logout() {
    return this.http.get(`${API_CONSTANTS.AUTH}${API_CONSTANTS.LOGOUT}`)
      .subscribe(() => {
        this.nightwebUtil.clearStorage();
        window.location.href = '/login';
      });
  }
}
