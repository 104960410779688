<div class="row justify-content-center">
    <div class="col col-sm-8 col-md-7 col-lg-5 col-xl-6 col-xxl-4">
        <div class="d-flex justify-content-center mb-4">
            <img class="bg-image" src="../../../assets/img/png/NexionSolutionslogo-Login.png" />
        </div>
        <form [formGroup]="resetPasswordForm" autocomplete="off">
            <div class="card">
                <div class="card-body p-3">
                    <h4 class="card-title text-center">{{ cardTitle }}</h4>

                    <div class="md-form mb-2">
                        <label for="newPassword" class="form-label">Password</label>
                        <div class="input-group flex-nowrap me-3"
                            [class.is-invalid]="resetPasswordForm.controls['newPassword'].dirty && resetPasswordForm.get('newPassword')?.invalid">
                            <div class="input-group input-group-lg">
                                <span class="input-group-text ps-2 pe-0"><i-bs name="lock-fill"></i-bs></span>
                                <input [type]="showNewPassword ? 'text' : 'password'" class="form-control px-1"
                                    id="loginPassword" formControlName="newPassword" />
                                <span (click)="showNewPassword = !showNewPassword"
                                    class="input-group-text px-1 cursor-pointer">
                                    <i-bs class="mx-2" [name]="showNewPassword ? 'eye' : 'eye-slash'"></i-bs>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="md-form mb-2">
                        <label for="confirmNewPassword" class="form-label">Confirm Password</label>
                        <div class="input-group flex-nowrap me-3"
                            [class.is-invalid]="resetPasswordForm.controls['confirmNewPassword'].dirty && resetPasswordForm.get('confirmNewPassword')?.invalid">
                            <div class="input-group input-group-lg">
                                <span class="input-group-text ps-2 pe-0"><i-bs name="lock-fill"></i-bs></span>
                                <input [type]="showConfirmPassword ? 'text' : 'password'" class="form-control px-1"
                                    id="confirmNewPassword" formControlName="confirmNewPassword" />
                                <span (click)="showConfirmPassword = !showConfirmPassword"
                                    class="input-group-text px-1 cursor-pointer">
                                    <i-bs class="mx-2" [name]="showConfirmPassword ? 'eye' : 'eye-slash'"></i-bs>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="md-form">
                        <span class="fs-1">Password must include</span>
                        <ul class="fa-ul text-left">
                            <li class="mb-1 fs-1">
                                <span class="fa-li">
                                    <i-bs [name]="
                        (resetPasswordForm.value.newPassword &&
                        resetPasswordForm.controls['newPassword'].dirty &&
                        resetPasswordForm.controls['newPassword'].valid ? 
                        'check-circle' : (
                            (resetPasswordForm.value.newPassword &&
                            resetPasswordForm.controls['newPassword'].dirty && 
                            resetPasswordForm.controls['newPassword'].invalid) ?
                            'x-circle' : 'exclamation-circle')
                        )" class="{{ 
                            (resetPasswordForm.value.newPassword && 
                            resetPasswordForm.controls['newPassword'].dirty && 
                            resetPasswordForm.controls['newPassword'].valid) ?
                            'text-success' : (
                                (resetPasswordForm.value.newPassword &&
                                resetPasswordForm.controls['newPassword'].dirty && 
                                resetPasswordForm.controls['newPassword'].invalid) ?
                                'text-danger' : 'text-gray-dark'
                            )
                        }}">
                                    </i-bs>
                                </span>
                                <small class="{{ 
                    (resetPasswordForm.value.newPassword && 
                    resetPasswordForm.controls['newPassword'].dirty && 
                    resetPasswordForm.controls['newPassword'].valid) ?
                    'text-success' : (
                        (resetPasswordForm.value.newPassword && 
                        resetPasswordForm.controls['newPassword'].dirty && 
                        resetPasswordForm.controls['newPassword'].invalid) ?
                        'text-danger' : 'text-gray-dark'
                    )
                    }}">
                                    At least 8 characters
                                </small>
                            </li>
                            <li class="mb-1 fs-1">
                                <span class="fa-li">
                                    <i-bs [name]="
                    (resetPasswordForm.value.newPassword &&
                    resetPasswordForm.controls['newPassword'].dirty &&
                    resetPasswordForm.controls['newPassword'].valid ? 
                    'check-circle' : (
                        (resetPasswordForm.value.newPassword &&
                        resetPasswordForm.controls['newPassword'].dirty && 
                        resetPasswordForm.controls['newPassword'].invalid) ?
                        'x-circle' : 'exclamation-circle')
                    )" class="{{ 
                        (resetPasswordForm.value.newPassword && 
                        resetPasswordForm.controls['newPassword'].dirty && 
                        resetPasswordForm.controls['newPassword'].valid) ?
                        'text-success' : (
                            (resetPasswordForm.value.newPassword &&
                            resetPasswordForm.controls['newPassword'].dirty && 
                            resetPasswordForm.controls['newPassword'].invalid) ?
                            'text-danger' : 'text-gray-dark'
                        )
                    }}">
                                    </i-bs>
                                </span>
                                <small class="{{ 
                    (resetPasswordForm.value.newPassword && 
                    resetPasswordForm.controls['newPassword'].dirty && 
                    resetPasswordForm.controls['newPassword'].valid) ?
                    'text-success' : (
                        (resetPasswordForm.value.newPassword && 
                        resetPasswordForm.controls['newPassword'].dirty && 
                        resetPasswordForm.controls['newPassword'].invalid) ?
                        'text-danger' : 'text-gray-dark'
                    )
                    }}">
                                    At least one Uppercase
                                </small>
                            </li>
                            <li class="mb-1 fs-1">
                                <span class="fa-li">
                                    <i-bs [name]="
                    (resetPasswordForm.value.newPassword &&
                    resetPasswordForm.controls['newPassword'].dirty && 
                    resetPasswordForm.controls['newPassword'].valid) ?
                    'check-circle' : (
                        (resetPasswordForm.value.newPassword &&
                        resetPasswordForm.controls['newPassword'].dirty && 
                        resetPasswordForm.controls['newPassword'].invalid) ?
                        'x-circle' : 'exclamation-circle'
                    )
                    " class="{{ 
                    (resetPasswordForm.value.newPassword && 
                    resetPasswordForm.controls['newPassword'].dirty && 
                    resetPasswordForm.controls['newPassword'].valid) ?
                    'text-success' : (
                        (resetPasswordForm.value.newPassword &&
                        resetPasswordForm.controls['newPassword'].dirty && 
                        resetPasswordForm.controls['newPassword'].invalid) ?
                        'text-danger' : 'text-gray-dark'
                    )
                }}">
                                    </i-bs>
                                </span>
                                <small class="{{ 
                    (resetPasswordForm.value.newPassword && 
                    resetPasswordForm.controls['newPassword'].dirty && 
                    resetPasswordForm.controls['newPassword'].valid) ?
                    'text-success' : (
                        (resetPasswordForm.value.newPassword &&
                        resetPasswordForm.controls['newPassword'].dirty && 
                        resetPasswordForm.controls['newPassword'].invalid) ?
                        'text-danger' : 'text-gray-dark'
                    )
                    }}">
                                    At least one special character i.e #@! and a number
                                </small>
                            </li>
                            <li class="mb-1 fs-1">
                                <span class="fa-li">
                                    <i-bs [name]="
                    (resetPasswordForm.value.confirmNewPassword &&
                    resetPasswordForm.controls['confirmNewPassword'].dirty && 
                    resetPasswordForm.controls['confirmNewPassword'].valid) ?
                    'check-circle' : (
                        (resetPasswordForm.value.confirmNewPassword &&
                        resetPasswordForm.controls['confirmNewPassword'].dirty && 
                        resetPasswordForm.controls['confirmNewPassword'].invalid) ?
                        'x-circle' : 'exclamation-circle'
                    )
                    " class="{{
                    (resetPasswordForm.value.confirmNewPassword && 
                    resetPasswordForm.controls['confirmNewPassword'].dirty && 
                    resetPasswordForm.controls['confirmNewPassword'].valid) ?
                    'text-success' : (
                        (
                        resetPasswordForm.value.confirmNewPassword &&
                        resetPasswordForm.controls['confirmNewPassword'].dirty && 
                        resetPasswordForm.controls['confirmNewPassword'].invalid) ?
                        'text-danger' : 'text-gray-dark'
                    )
                }}">
                                    </i-bs>
                                </span>
                                <small class="{{ 
                    (resetPasswordForm.value.confirmNewPassword && 
                    resetPasswordForm.controls['confirmNewPassword'].dirty && 
                    resetPasswordForm.controls['confirmNewPassword'].valid) ?
                    'text-success' : (
                        (resetPasswordForm.value.confirmNewPassword &&
                        resetPasswordForm.controls['confirmNewPassword'].dirty && 
                        resetPasswordForm.controls['confirmNewPassword'].invalid) ?
                        'text-danger' : 'text-gray-dark'
                    )
                    }}">
                                    New Password and Confirm Password matched
                                </small>
                            </li>
                        </ul>
                    </div>

                    <button type="submit" class="btn btn-lg btn-block btn-primary" debounceClick
                        (debounceClick)="resetPassword()" [disabled]="loading || resetPasswordForm.invalid">
                        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"
                            *ngIf="loading"></span>
                        Set Password
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="row justify-content-center mt-4">
    <div class="col text-center">
        <span>Copyright © {{date | date: 'yyyy'}} Nexion. </span>
        <span>Privacy | Terms of use </span>
    </div>
</div>