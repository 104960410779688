import { Injectable } from "@angular/core";

@Injectable()

export class FormatPhone {
    public phoneNumber(phone: any) {
        let cleaned = ('' + phone).replace(/\D/g, '');
        cleaned = cleaned.slice(-10)
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    }
}