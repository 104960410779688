import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs';
import { NIGHTWEB_APP_CONSTANT, STATUS_CODES } from 'src/app/constants';
import { AuthService } from 'src/app/services';
import { NightwebUtil } from 'src/app/utils';
import {
  ForgotPasswordModalComponent,
  VerificationPinModalComponent,
} from '../modals';
import { ClientJS } from 'clientjs';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  loading = false;
  submitted = false;
  showLoginPassword = false;
  date = new Date();
  loggedInUser!: any;
  browserFingerprint!: any;
  status_codes = STATUS_CODES;
  config = NIGHTWEB_APP_CONSTANT.modalConfig;
  verificationPinModalRef!: MdbModalRef<VerificationPinModalComponent>;
  forgotPasswordModalRef!: MdbModalRef<ForgotPasswordModalComponent>;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private nightwebUtil: NightwebUtil,
    private modalService: MdbModalService
  ) {
    this.browserFingerprint = new ClientJS().getFingerprint();
    this.setLoginForm();
  }

  ngOnInit(): void {
    this.loggedInUser = this.nightwebUtil.getLoggedInUser();
    if (this.loggedInUser) {
      this.router.navigateByUrl('/');
    }
  }

  setLoginForm() {
    this.loginForm = this.formBuilder.group({
      userName: [
        null,
        [
          Validators.required,
          Validators.pattern(NIGHTWEB_APP_CONSTANT.emailPattern),
        ],
      ],
      password: [
        null,
        [
          Validators.required,
          Validators.pattern(NIGHTWEB_APP_CONSTANT.passwordPattern),
        ],
      ],
      browserFingerprint: [
        this.browserFingerprint.toString(),
        Validators.required,
      ],
      rememberMe: [false],
    });
  }

  login() {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.loading = true;
      const data = {
        ...this.loginForm.value,
        //TODO: hashed password
        // password: CryptoJS.SHA256(this.loginForm.value.password).toString(
        //   CryptoJS.enc.Base64
        // ),
      };
      this.authService
        .login(data)
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe({
          next: (response) => {
            if (response.statusCode == this.status_codes.OK) {
              if (!response.data.isAuthenticated) {
                this.openVerificationModal(response.data);
              } else {
                if (response?.data?.authTokenDetails?.token) {
                  this.nightwebUtil.setLoggedInUser(
                    response.data.authTokenDetails.token
                  );
                  this.router.navigate(['/end-users']);
                }
              }
            }
          },
          error: (e) => {},
        });
    }
  }

  openVerificationModal(data: any) {
    const config = this.nightwebUtil.shallowCopy(this.config);
    config.modalClass = 'modal-dialog-centered modal-sm';
    config.data.formData.userName = this.loginForm.get('userName')?.value;
    config.data.formData.rememberPassword =
      this.loginForm.get('rememberMe')?.value;
    config.data.formData.multiFactorAuthExpiryPeriodInDays =
      data.multiFactorAuthExpiryPeriodInDays;
    config.data.formData.verificationCodeExpiryPeriodInMinutes =
      data.verificationCodeExpiryPeriodInMinutes;
    this.verificationPinModalRef = this.modalService.open(
      VerificationPinModalComponent,
      config
    );
    this.verificationPinModalRef.onClose.subscribe((res: any) => {
      if (res) {
        // this.router.navigate(['/end-users']);
      }
    });
  }

  openForgotPassword() {
    const config = this.nightwebUtil.shallowCopy(this.config);
    config.modalClass = 'modal-dialog-centered modal-dialog-scrollable';
    this.forgotPasswordModalRef = this.modalService.open(
      ForgotPasswordModalComponent,
      config
    );
  }
}
