import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

  @Input() form!: FormGroup;
  @Input() control!: any;
  @Input() checkBoxId: string = '';
  @Input() checkBoxLabel: string = '';

  @Output() emitCheckEvent = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  change(event: any) {
    this.emitCheckEvent.emit(event.target.checked);
  }

}
