<div class="input-group border bg-white rounded">
    <span class="input-group-text bg-white border-0">
        <i-bs name="search"></i-bs>
    </span>
    <input [ngModel]="(searchKeyword | async)" type="text" class="form-control bg-white border-0 ps-0"
    id="searchKeyword" [placeholder]="placeholder" autocomplete="off" (ngModelChange)="searchKeyword.next($event)" />
    <span class="input-group-text bg-white border-0">
        <span (click)="searchKeyword.next('')" *ngIf="searchKey">
            <i-bs name="x-lg"></i-bs>
        </span>
    </span>
</div>