<div class="table-responsive">
    <table class="table align-middle">
        <thead class="bg-gray-light text-gray-dark">
            <tr>
                <th>Role Name</th>
                <th class="w-25"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let role of roles">
                <td>{{role?.name}}</td>
                <td>
                    <button type="button" class="btn btn-outline-primary text-primary" (click)="openPermissionModal(role)">View Permission</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>