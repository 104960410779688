import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { map, takeUntil, shareReplay } from 'rxjs/operators';
import { TimeComponents } from '../interface/countdown-timer.interface';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
})
export class CountdownTimerComponent implements OnInit, AfterViewInit {
  timeLeft$!: Observable<TimeComponents>;
  countdownEndTime: number = 0;
  timeDifference: number = 0;
  countdownDurationInMinutes: string = '01';
  countdownDurationInSeconds: string = '00';
  isLoading: boolean = false;
  private countdownSubscription!: Subscription;
  private stop$!: Subject<void>; // Subject to signal when to stop the countdown

  @Input() countdownDurationInMilliseconds: number = 60000;
  @Input() isButtonDisabled: boolean = false;
  @Input() btnLabels?: any = {
    start: 'Start',
    stop: 'Stop',
  };
  @Output() handleCountdownAction = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.getCountdownDuration();
  }

  // Convert the passed milliseconds to minutes and seconds
  getCountdownDuration() {
    this.countdownDurationInMinutes = Math.floor(
      this.countdownDurationInMilliseconds / 60000
    )
      .toString()
      .padStart(2, '0');

    this.countdownDurationInSeconds = Math.floor(
      (this.countdownDurationInMilliseconds % 60000) / 1000
    )
      .toString()
      .padStart(2, '0');
  }

  onCountdownActionClick() {
    this.handleCountdownAction.emit(
      this.timeDifference <= 0 ? 'start' : 'stop'
    );
  }

  onStartCountdown() {
    this.isLoading = true;
    // Reinitialize stop$ subject to allow stopping the countdown again
    this.stop$ = new Subject<void>();

    // Set the target end time to provided duration in milliseconds
    this.countdownEndTime = Date.now() + this.countdownDurationInMilliseconds; // Use the milliseconds directly

    // Start the countdown and update every second
    this.timeLeft$ = interval(1000).pipe(
      map(() => this.calcDateDiff()), // Calculate the time left on each tick
      takeUntil(this.stop$), // Use takeUntil to stop when stop$ emits
      shareReplay(1) // Share the latest value with all subscribers
    );

    // Subscribe to the observable and store the subscription
    this.countdownSubscription = this.timeLeft$.subscribe();
  }

  onStopCountdown() {
    if (this.countdownSubscription) {
      // Emit a value to the stop$ subject to signal the observable to stop
      this.stop$.next();
      this.stop$.complete(); // Optionally complete the subject
      this.countdownSubscription.unsubscribe(); // Unsubscribe to stop the countdown
      this.timeDifference = 0;
    }
  }

  calcDateDiff(): { minutesToDday: string; secondsToDday: string } {
    const milliSecondsInASecond = 1000;
    const secondsInAMinute = 60;

    // Calculate the remaining time in milliseconds
    this.timeDifference = this.countdownEndTime - Date.now();

    if (this.timeDifference <= 0) {
      this.onStopCountdown();
      return { minutesToDday: '00', secondsToDday: '00' }; // Time's up
    }

    const minutesToDday = Math.floor(
      this.timeDifference / (milliSecondsInASecond * secondsInAMinute)
    )
      .toString()
      .padStart(2, '0'); // Ensure two digits

    const secondsToDday = Math.floor(
      (this.timeDifference / milliSecondsInASecond) % secondsInAMinute
    )
      .toString()
      .padStart(2, '0');

    this.isLoading = false;

    return { minutesToDday, secondsToDday };
  }
}
