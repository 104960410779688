import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NightwebUtil } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  loggedInUser: any;
  constructor(
    private router: Router,
    private nightwebUtil: NightwebUtil
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.loggedInUser = this.nightwebUtil.getLoggedInUser();
      const [routeData] = Object.keys(route.data).map((key) => route.data[key]);
      if (this.nightwebUtil.isAccessible(routeData['resourceName'], routeData['accessType'])) {
        return true;
      }
      if (!this.loggedInUser) {
        this.router.navigate(['/login']);
        return false;
      } 
      this.router.navigate(['/end-users']);
      return false;
  }

}
