<div class="popconfirm-popover shadow-4">
    <div class="popconfirm">
        <p class="modal-title border-bottom mb-2">
            {{title}}
        </p>
        <p class="popconfirm-message">
            <span class="popconfirm-message-text">{{ body }}</span>
        </p>
        <div class="popconfirm-buttons-container">
            <button (click)="popconfirmRef.close()" type="button" aria-label="Cancel" class="btn btn-light btn-sm">
                {{ cancelButtonLabel }}
            </button>
            <button (click)="popconfirmRef.confirm()" type="button" aria-label="Confirm" class="btn btn-danger btn-sm">
                {{ confirmButtonLabel }}
            </button>
        </div>
    </div>
</div>