<div class="modal-body my-2">
  <form [formGroup]="verificationForm" autocomplete="off">
    <div class="text-center">
      <h4 class="mb-3" id="exampleModalLabel">Verification Confirmation</h4>
      <small class="d-block blue-grey-text">We've just sent an email with a verification code to your email
        address.</small>
      <div class="row mt-3">
        <div class="col-11 mx-auto">
          <code-input #codeInput [isCodeHidden]="false" [codeLength]="6" (codeChanged)="onCodeChanged($event)"
            (codeCompleted)="onCodeCompleted($event)">
          </code-input>
          <p class="mt-3 mb-0 small" *ngIf="!codeExpired">The code is valid for <span class="text-dark">
              {{formData?.verificationCodeExpiryPeriodInMinutes}} minutes
            </span></p>
        </div>
      </div>
      <!-- <div class="d-flex mt-2 justify-content-center">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" id="rememberMFACode" formControlName="rememberMFACode">
            <label class="form-check-label" for="rememberMFACode">
                Remember MFA Code for {{formData?.multiFactorAuthExpiryPeriodInDays}} days
            </label>
        </div>
      </div> -->
      <p class="mt-2 mb-3">{{ !codeExpired ? 'Didn\'t receive the code?' : 'Code has expired. Request for a new one.' }}
        <a class="text-primary" debounceClick (debounceClick)="resendVerificationCode()">Resend</a>
      </p>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <a class="text-primary" (click)="cancel()"><i class="fas fa-chevron-left"></i>&nbsp;Back to Login</a>
      <!-- <button type="button" class="btn btn-primary" (click)="verify()"
        [disabled]="code.length !== 4 || loading || verificationForm.invalid">
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
        Verify
      </button> -->
    </div>
  </form>
</div>