import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs';
import { MESSAGE_CONSTANTS, NIGHTWEB_APP_CONSTANT } from 'src/app/constants';
import { AuthService, ToasterService } from 'src/app/services';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password-modal.component.html',
  styleUrls: ['./forgot-password-modal.component.scss']
})
export class ForgotPasswordModalComponent implements OnInit {

  loading = false;
  submitted = false;
  forgotPasswordForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private toaster: ToasterService,
    private authService: AuthService,
    public modalRef: MdbModalRef<ForgotPasswordModalComponent>,
  ) {
    this.setForgetPasswordForm();
  }

  ngOnInit(): void {
  }

  setForgetPasswordForm() {
    this.forgotPasswordForm = this.fb.group({
      userName: ['', [Validators.required, Validators.pattern(NIGHTWEB_APP_CONSTANT.emailPattern)]]
    })
  }

  submit() {
    this.submitted = true;
    if (this.forgotPasswordForm.valid) {
      this.loading = true;
      this.authService.forgotPassword(this.forgotPasswordForm.value)
        .pipe(finalize(() => { this.loading = false; }))
        .subscribe({
          next: (response) => {
            this.modalRef.close();
            this.toaster.show('Success',response.message);
          },
          error: (e) => {
            this.toaster.showError(e);
          }
        })
    }
  }

}
