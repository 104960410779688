import { Injectable } from '@angular/core';
import { MdbNotificationRef, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { ToasterComponent } from '../components';
import { NightwebUtil } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(
    private nightWebUtil: NightwebUtil,
    private notificationService: MdbNotificationService
    ) { }

  notificationRef!: MdbNotificationRef<ToasterComponent>;
  toasterConfig = {
    delay: 5000,
    autohide: true,
    stacking: true,
    animation: true,
    offset: 50,
    data: {
      title: '',
      message: '',
    },
  }

  show(title: string, message: string) {
    const config = this.nightWebUtil.shallowCopy(this.toasterConfig)
    config.data = { title, message }
    this.notificationRef = this.notificationService.open(ToasterComponent, config);
  }

  showError(error: any) {
    const config = this.nightWebUtil.shallowCopy(this.toasterConfig)
    if (error.error?.message)
      config.data = { title: 'Error', message: error.error.message }
    else if (error && error.message)
      config.data = { title: 'Error', message: error?.message }
    else
      config.data = { title: 'Error', message: error.error?.message }
    this.notificationService.open(ToasterComponent, config);
  }
}
