import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { finalize, forkJoin } from 'rxjs';
import { MESSAGE_CONSTANTS, NIGHTWEB_APP_CONSTANT, STATUS_CODES } from 'src/app/constants';
import { Role } from 'src/app/enums';
import { AdminService, OrganizationService, RoleService, ToasterService } from 'src/app/services';

@Component({
  selector: 'app-add-edit-administrator',
  templateUrl: './add-edit-administrator-modal.component.html',
  styleUrls: ['./add-edit-administrator-modal.component.scss']
})
export class AddEditAdministratorModalComponent implements OnInit {

  administratorForm!: FormGroup;

  loading = false;
  submitted = false;

  title!: string;
  formData: any = {};
  organizationsList: any[] = [];
  roles: any[] = [];
  isAdvocateSelected: boolean = false;
  status_codes = STATUS_CODES;
  ROLE = Role;
  disableOrg: boolean = false;
  disableRole: boolean = false;
  phoneMask = NIGHTWEB_APP_CONSTANT.phoneMask;
  
  constructor(
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    private adminService: AdminService,
    private toasterService: ToasterService,
    private organizationService: OrganizationService,
    public modalRef: MdbModalRef<AddEditAdministratorModalComponent>
  ) {
    this.setAdmininstratorForm();
  }

  ngOnInit(): void {
    this.loading = true;
    forkJoin([
      this.organizationService.getAllOrganizations(),
      this.roleService.getAllRoles()
    ])
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          const [organizations, roles] = response;
          this.organizationsList = organizations.data.rows;
          this.roles = roles.data.rows;
          // CHECK IF ADMIN -> YES -> FILTER OUT NIGHTWATCH ADMIN ROLE
          if (this.formData?.organizations?.length > 0 || this.title === 'Add') {
            this.roles = this.roles.filter(role => role.code !== 'SUPER_ADMIN');
          }
          if (this.formData) {
            this.administratorForm.patchValue(this.formData);
            this.formData?.organizations?.length > 0 && this.administratorForm.get('organizations')?.patchValue({
              ...this.formData?.organizations[0]
            });
            this.checkRequiredValidation();
            this.disableOrg = true;
            this.disableRole = true;
          } else {
            if (this.organizationsList.length == 1) {
              this.organizations.patchValue({
                organizationId: this.organizationsList[0].id
              })
            }
          }
        },
        error: (e) => { }
      })
  }

  get demographic(): FormGroup {
    return this.administratorForm.get('demographic') as FormGroup;
  }

  get organizations(): FormGroup {
    return this.administratorForm.get('organizations') as FormGroup;
  }

  get emergencyNotifications(): FormGroup {
    return this.administratorForm.get('emergencyNotifications') as FormGroup;
  }

  get warningNotifications(): FormGroup {
    return this.administratorForm.get('warningNotifications') as FormGroup;
  }

  get followUpNotifications(): FormGroup {
    return this.administratorForm.get('followUpNotifications') as FormGroup;
  }

  setAdmininstratorForm() {
    this.administratorForm = this.formBuilder.group({
      id: [],
      userName: [''],
      demographic: this.formBuilder.group({
        firstName: ['', Validators.required],
        middleName: [''],
        lastName: [''],
        gender: ['Male'],
        phone: ['', Validators.required],
        email: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(NIGHTWEB_APP_CONSTANT.emailPattern)]],
      }),
      organizations: this.formBuilder.group({
        organizationId: [''],
        roleId: [''],
        additionalPermissions: [],
      }),
      emergencyNotifications: this.formBuilder.group({
        email: [false],
        sms: [false]
      }),
      warningNotifications: this.formBuilder.group({
        email: [false],
        sms: [false]
      }),
      followUpNotifications: this.formBuilder.group({
        email: [false],
        sms: [false]
      }),
    });
    this.checkRequiredValidation();
  }

  checkRequiredValidation() {
    if (!this.formData.superRoleId) {
      this.organizations.controls['organizationId'].setValidators([Validators.required]);
      this.organizations.controls['roleId'].setValidators([Validators.required]);
    } else {
      this.organizations.controls['organizationId'].clearValidators();
      this.organizations.controls['roleId'].clearValidators();
    }
    this.organizations.controls['organizationId'].updateValueAndValidity();
    this.organizations.controls['roleId'].updateValueAndValidity();
  }

  getAllOrganizations() {
    this.loading = true;
    this.organizationService.getAllOrganizations()
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          this.organizationsList = response.data.rows;
        },
        error: (e) => {
        }
      })
  }

  confirm() {
    this.modalRef.close();
  }

  close() {
    this.submitted = false;
    this.modalRef.close();
  }

  save() {
    if (this.administratorForm.valid) {
      this.loading = true;
      this.submitted = true;
      const admin = {
        ...this.administratorForm.getRawValue(),
        userName: this.demographic.get('email')?.value,
        organizations: [this.organizations.value]
      };
      if (this.administratorForm.get('id')?.value) {

        this.adminService.update(admin)
          .pipe(finalize(() => { this.loading = false; this.submitted = false; }))
          .subscribe({
            next: (response) => {
              if (response.statusCode == this.status_codes.OK) {
                this.modalRef.close(response.data);
                this.toasterService.show('Success', MESSAGE_CONSTANTS.ADMIN_UPDATED);
              }
            },
            error: (e) => {
            }
          })
      } else {
        this.adminService.create(admin)
          .pipe(finalize(() => { this.loading = false; this.submitted = false; }))
          .subscribe({
            next: (response) => {
              if (response.statusCode == this.status_codes.OK) {
                this.modalRef.close(response.data);
                this.toasterService.show('Success', MESSAGE_CONSTANTS.ADMIN_ADDED);
              }
            },
            error: (e) => {
            }
          })
      }
    }
  }

  receiveSelectedValue(event: any) {
    this.administratorForm?.get('organizations')?.patchValue({
      [event.controlName]: event.value
    });
    if (event.controlName === 'roleId') {
      this.isAdvocateSelected = this.roles.find((role: any) => role.code === 'ADVOCATE').id === event.value;
    if (this.isAdvocateSelected) {
      this.emergencyNotifications.patchValue({ email: true, sms: true });
      this.warningNotifications.patchValue({ email: true, sms: true });
      this.followUpNotifications.patchValue({ email: true, sms: true });
    } else {
      this.emergencyNotifications.patchValue({ email: false, sms: false });
      this.warningNotifications.patchValue({ email: false, sms: false });
      this.followUpNotifications.patchValue({ email: false, sms: false });
    }
    };
  }
}
