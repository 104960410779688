import {
    person,
    personFill,
    personPlus,
    eye,
    eyeSlash,
    eyeSlashFill,
    envelopeFill,
    stack,
    list,
    search,
    plus,
    chevronLeft,
    chevronRight,
    threeDotsVertical,
    pencilSquare,
    trash,
    journalText,
    filter,
    chevronDown,
    lockFill,
    x,
    xLg,
    watch,
    diagram3Fill,
    xCircle,
    checkCircle,
    infoCircle,
    exclamationCircle,
    peopleFill,
    shieldLockFill,
    clockFill
} from 'ngx-bootstrap-icons';

export const icons = {
    person,
    personFill,
    personPlus,
    eye,
    eyeSlash,
    eyeSlashFill,
    envelopeFill,
    stack,
    list,
    search,
    plus,
    chevronLeft,
    chevronRight,
    threeDotsVertical,
    pencilSquare,
    trash,
    journalText,
    filter,
    chevronDown,
    lockFill,
    x,
    xLg,
    watch,
    diagram3Fill,
    xCircle,
    checkCircle,
    infoCircle,
    exclamationCircle,
    peopleFill,
    shieldLockFill,
    clockFill
};