<div class="d-flex justify-content-between">
  <app-search (emitSearchKeyword)="receiveSearchKeyword($event)"></app-search>
  <div class="d-flex">
    <app-select
      [form]="deviceForm"
      [control]="deviceForm.controls['limit']"
      [controlName]="'limit'"
      [filter]="false"
      [submitted]="submitted"
      [data]="limits"
      [name]="'label'"
      [value]="'value'"
      [width]="defaultLimitSelectWidth"
      (emitSelectedValue)="receiveSelectedValue($event)"
      class="me-2 select-outline"
    ></app-select>
    <button
      type="button"
      class="btn btn-primary me-2"
      (click)="openUploadModal()"
      *ngIf="isWriteAccessible"
    >
      <i class="fas fa-upload"></i> Upload
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="openAddEditDeviceModal()"
      *ngIf="isWriteAccessible"
    >
      <i-bs name="plus"></i-bs> Add Device
    </button>
  </div>
</div>

<div class="mt-3">
  <app-table
    [tableConfig]="tableConfig"
    [module]="'Check in module'"
    [loading]="loading"
    (onScrollToBottom)="scrollToBottom($event)"
    [notFoundMessage]="notFoundMessage"
    (sendEvent)="receiveEvent($event)"
  >
  </app-table>
</div>
