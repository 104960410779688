import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { MESSAGE_CONSTANTS, NIGHTWEB_APP_CONSTANT } from 'src/app/constants';
import { PasswordValidation } from 'src/app/directives';
import { AuthService, ToasterService } from 'src/app/services';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm!: FormGroup;

  loading = false;
  submitted = false;
  showNewPassword = false;
  showConfirmPassword = false;
  date = new Date();
  resetToken: string = '';
  cardTitle: string = 'Set Password';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private toasterService: ToasterService
  ) {
    const { url } = this.router;
    if (url?.includes('/reset-password')) {
      this.cardTitle = 'Reset Password';
    } else {
      this.cardTitle = 'Set Password';
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['token']) {
        this.resetToken = params['token']
      }
    })
    this.setResetPasswordForm();
  }

  setResetPasswordForm() {
    this.resetPasswordForm = this.formBuilder.group({
      newPassword: ['', [
        Validators.required,
        Validators.minLength(NIGHTWEB_APP_CONSTANT.passwordMinLength),
        Validators.pattern(NIGHTWEB_APP_CONSTANT.passwordPattern)
      ]],
      confirmNewPassword: ['', [
        Validators.required,
        Validators.minLength(NIGHTWEB_APP_CONSTANT.passwordMinLength),
        Validators.pattern(NIGHTWEB_APP_CONSTANT.passwordPattern)
      ]],
      verifyToken: [this.resetToken, Validators.required]
    },
      { validator: PasswordValidation.MatchPassword })
  }

  resetPassword() {
    if (this.resetPasswordForm.valid) {
      this.loading = true;
      this.authService.resetPassword(this.resetPasswordForm.value)
        .pipe(finalize(() => this.loading = false))
        .subscribe({
          next: (response) => {
            this.toasterService.show('Success', MESSAGE_CONSTANTS.RESET_PASSWORD);
            this.authService.logout();
          },
          error: (e) => {
          }
        })
    }
  }

}
