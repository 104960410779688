import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MdbSidenavComponent } from 'mdb-angular-ui-kit/sidenav';
import { DateUtility, NightwebUtil } from '../../../utils';

@Component({
  selector: 'app-end-user-profile-information',
  templateUrl: './end-user-profile-information.component.html',
  styleUrls: ['./end-user-profile-information.component.css'],
})
export class EndUserProfileInformationComponent implements OnInit {

  @Input() data !: any;
  @ViewChild('sidenav', { static: false }) public sidenav!: MdbSidenavComponent;
  enduserName: string = '';
  enduserDOB: string = '';
  perpetratorDOB: string = '';

  constructor(
    private dateUtil: DateUtility,
    private nightwebUtil: NightwebUtil,
  ) {}

  ngOnInit(): void {}

  onOpenSidenav() {
    console.log("DDD11:", this.data)
    this.enduserName = this.nightwebUtil.getDisplayName(this.data.demographic);
    this.enduserDOB = this.dateUtil.convertToMMddYYYY(this.data.demographic.dob);
    this.perpetratorDOB = this.dateUtil.convertToMMddYYYY(this.data.info.perpetratorDob);
    this.sidenav.toggle();
  }

  onClose() {
    this.sidenav.toggle();
  }
}
