<mdb-sidenav-layout>
  <mdb-sidenav
    #sidenav="mdbSidenav"
    data-cy="sidenav-notes"
    [width]="400"
    [right]="true"
  >
    <div class="position-relative h-100">
      <header
        class="d-flex justify-content-between align-items-center p-2 navbar"
      >
        <div class="d-flex align-items-center">
          <p class="mb-0 me-1 fw-bold text-muted">Notes</p>
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="isFetchingNotes"
          ></span>
        </div>
        <button type="button" class="btn-transparent" (click)="onClose()">
          <i class="fas fa-xmark"></i>
        </button>
      </header>
      <body class="p-2 pb-2 scrollbar-primary thin overflow-auto note-body">
        <div
          class="row gx-2 pt-2"
          *ngFor="let datum of data; let index = index"
          [class]="selectedNoteIndex === index && 'bg-gray-lighter'"
        >
          <div class="col-2 ps-1">
            <span
              class="nav-link avatar p-0 bg-success text-center text-white fw-bold"
            >
              {{ datum.updatedBy.initials }}
            </span>
          </div>
          <div class="col-8">
            <p class="m-0">
              {{ datum.updatedBy.firstName }} {{ datum.updatedBy.middleName }}
              {{ datum.updatedBy.lastName }}
            </p>
            <small>{{ datum.updatedAt | date : defaultDateTimeFormat }}</small>
            <p class="mt-2" [innerHTML]="datum.currentText"></p>
          </div>
          <div class="col-2 text-end">
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
              *ngIf="selectedNoteIndex === index && isLoading.deletingNote"
            ></span>
            <div *ngIf="selectedNoteIndex !== index || !isLoading.deletingNote">
              <button
                type="button"
                class="btn-transparent"
                (click)="onEdit(datum, index)"
              >
                <i class="far fa-pen-to-square text-primary"></i>
              </button>
              <button
                type="button"
                class="btn-transparent"
                (click)="onDelete(datum.id, $event, index)"
              >
                <i class="fas fa-trash-can text-danger"></i>
              </button>
            </div>
          </div>
          <hr *ngIf="index !== data.length - 1" class="mb-0" />
        </div>
      </body>
      <footer class="position-absolute bottom-0 p-2 w-100 note-footer">
        <form [formGroup]="noteForm">
          <angular-editor
            [config]="editorConfig"
            formControlName="body"
          ></angular-editor>
          <div class="d-flex justify-content-between mt-2">
            <button
              type="button"
              class="btn btn-gray-light"
              (click)="
                selectedNoteIndex || selectedNoteIndex === 0
                  ? onCancel('update')
                  : onCancel('add')
              "
              [class.disabled]="
                noteForm.invalid ||
                isLoading.addingNote ||
                isLoading.updatingNote
              "
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary"
              (click)="
                selectedNoteIndex || selectedNoteIndex === 0
                  ? onSubmit('update')
                  : onSubmit('add')
              "
              [class.disabled]="
                noteForm.invalid ||
                isLoading.addingNote ||
                isLoading.updatingNote
              "
            >
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
                *ngIf="isLoading.addingNote || isLoading.updatingNote"
              ></span>
              {{
                selectedNoteIndex || selectedNoteIndex === 0 ? "Update" : "Add"
              }}
            </button>
          </div>
        </form>
      </footer>
    </div>
  </mdb-sidenav>
  <mdb-sidenav-content #sidenavContent></mdb-sidenav-content>
</mdb-sidenav-layout>
