import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LayoutMainComponent } from './components';
import { AuthGuard } from './guards';
import { RoutePublic, RouteSecure } from './routes';

const routes: Routes = [
  { path: '',  component: LayoutMainComponent, children: RouteSecure, canActivate: [AuthGuard] },
  { path: "", children: RoutePublic },
  { path: "**", redirectTo: "/" }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: false
    })
  ], exports: [RouterModule]
})
export class AppRoutingModule { }
