<div class="d-flex align-items-center justify-content-between">
    <div class="countdown-timer">
        <span>{{ timeDifference > 0 ? (timeLeft$ | async)?.minutesToDday : countdownDurationInMinutes }}</span><sub>Min</sub> 
        <span>:</span>
        <span>{{ timeDifference > 0 ? (timeLeft$ | async)?.secondsToDday : countdownDurationInSeconds }}</span><sub>Sec</sub>
      </div>
    <button
        class="btn btn-primary"
        (click)="onCountdownActionClick()"
        [disabled]="isButtonDisabled || isLoading"
        
    >
        <small>{{ this.timeDifference <= 0 ? btnLabels.start : btnLabels.stop }}</small>
    </button>
</div>