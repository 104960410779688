import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, retry, switchMap } from 'rxjs/operators';
import { AuthService, ToasterService } from '../services';
import { MESSAGE_CONSTANTS, STATUS_CODES } from '../constants';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  status_codes = STATUS_CODES;
  refreshRetry = 0;
  constructor(
    private authService: AuthService,
    private toasterService: ToasterService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request.clone()).pipe(
      retry(this.getRetryCount(request)),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 400 && !error?.error?.message) {
          this.toasterService.show('Error', MESSAGE_CONSTANTS.BAD_REQUEST);
        } else if (
          error.status === 401 &&
          !request.url.includes('/auth/refresh')
        ) {
          return this.authService.refresh().pipe(
            switchMap((response) => {
              if (response.statusCode == this.status_codes.OK) {
                return next.handle(request);
              } else if (
                response.statusCode === this.status_codes.UNAUTHORIZED
              ) {
                localStorage.clear();
                window.location.href = '/login';
              }
              return next.handle(request);
            }),
            catchError((err: HttpErrorResponse) => {
              if (error.status === 401) {
                this.toasterService.show(
                  'Error',
                  MESSAGE_CONSTANTS.SESSION_EXPIRED
                );
                localStorage.clear();
                window.location.href = '/login';
              }
              throw err;
            })
          );
        } else if (
          error.status === 401 &&
          request.url.includes('/auth/refresh')
        ) {
          throw error;
        } else if (error.status == 403) {
          this.toasterService.show('Error', MESSAGE_CONSTANTS.FORBIDDEN);
        } else if (error.status == 500 && !error?.error?.message ) {
          this.toasterService.show('Error', MESSAGE_CONSTANTS.SERVER_ERROR);
        } else if (error.status == 504) {
          this.toasterService.show('Error', MESSAGE_CONSTANTS.TIMEOUT);
        } else if (error.error) {
          this.toasterService.show(
            'Error',
            error.error?.message || MESSAGE_CONSTANTS.SERVICE_UNAVAILABLE
          );
          // throw error;
        }
        throw error;
      })
    );
  }

  getRetryCount(request: HttpRequest<any>) {
    if (request.url.includes('/auth/refresh')) {
      return 2;
    }
    return 0;
  }
}
