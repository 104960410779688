<form [formGroup]="administratorForm" autocomplete="off">
  <div class="d-flex justify-content-between">
    <div class="d-flex justify-content-start">
      <app-search
        (emitSearchKeyword)="receiveSearchKeyword($event)"
      ></app-search>
    </div>
    <div class="d-flex justify-content-end align-items-center">
      <app-select
        [form]="administratorForm"
        [control]="administratorForm.controls['organizationId']"
        [controlName]="'organizationId'"
        [disabled]="!organizations.length"
        [clearButton]="clientAdmin"
        [filter]="false"
        [placeholder]="
          organizations.length ? 'Select Organization' : 'Loading...'
        "
        [submitted]="submitted"
        [data]="organizations"
        [name]="'label'"
        [value]="'value'"
        [width]="'260px'"
        (emitSelectedValue)="receiveSelectedValue($event)"
        class="select-outline ms-2"
      >
      </app-select>
      <app-select
        [form]="administratorForm"
        [control]="administratorForm.controls['roleId']"
        [controlName]="'roleId'"
        [filter]="false"
        [placeholder]="roles.length ? 'Select Roles' : 'Loading...'"
        [disabled]="!roles.length"
        [submitted]="submitted"
        [data]="roles"
        [name]="'label'"
        [value]="'value'"
        [width]="'180px'"
        [clearButton]="true"
        (emitSelectedValue)="receiveSelectedValue($event)"
        class="select-outline ms-2"
      >
      </app-select>
      <app-select
        [form]="administratorForm"
        [control]="administratorForm.controls['limit']"
        [controlName]="'limit'"
        [filter]="false"
        [submitted]="submitted"
        [data]="limits"
        [name]="'label'"
        [value]="'value'"
        [width]="defaultLimitSelectWidth"
        (emitSelectedValue)="receiveSelectedValue($event)"
        class="select-outline ms-2"
      >
      </app-select>
      <button
        type="button"
        class="btn btn-primary ms-2"
        (click)="openAddAdministratorModal()"
        *ngIf="isWriteAccessible"
      >
        <i-bs name="plus"></i-bs> Add Administrator
      </button>
    </div>
  </div>

  <div class="mt-3">
    <app-table
      [tableConfig]="tableConfig"
      [module]="'Organization module'"
      [loading]="loading"
      (onScrollToBottom)="scrollToBottom($event)"
      [notFoundMessage]="notFoundMessage"
      (sendEvent)="receiveEvent($event)"
    >
    </app-table>
  </div>
</form>
