import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs';
import { MESSAGE_CONSTANTS, NIGHTWEB_APP_CONSTANT } from 'src/app/constants';
import { PasswordValidation } from 'src/app/directives';
import { AuthService, ToasterService } from 'src/app/services';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm!: FormGroup;

  loading: boolean = false;
  submitted: boolean = false;

  showLoginPassword: boolean = false;
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private toasterService: ToasterService
  ) {
    this.setChangePasswordForm();
  }

  ngOnInit(): void {
  }

  setChangePasswordForm() {
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', [
        Validators.required,
        Validators.minLength(NIGHTWEB_APP_CONSTANT.passwordMinLength),
        Validators.pattern(NIGHTWEB_APP_CONSTANT.passwordPattern)
      ]
      ],
      newPassword: ['', [
        Validators.required,
        Validators.minLength(NIGHTWEB_APP_CONSTANT.passwordMinLength),
        Validators.pattern(NIGHTWEB_APP_CONSTANT.passwordPattern)
      ]],
      confirmNewPassword: ['', [
        Validators.required,
        Validators.minLength(NIGHTWEB_APP_CONSTANT.passwordMinLength),
        Validators.pattern(NIGHTWEB_APP_CONSTANT.passwordPattern)
      ]]
    },
      { validator: PasswordValidation.MatchPassword })
  }

  save() {
    if (this.changePasswordForm.valid) {
      this.loading = true;
      this.authService.changePassword(this.changePasswordForm.value)
        .pipe(finalize(() => this.loading = false))
        .subscribe({
          next: (response) => {
            this.toasterService.show('Success', MESSAGE_CONSTANTS.CHANGED_PASSWORD);
            this.authService.logout();
          },
          error: (e) => {
          }
        })
    }
  }

  cancel() {
    this.setChangePasswordForm();
  }

}
