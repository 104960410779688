<mdb-sidenav-layout>
  <mdb-sidenav
    #sidenav="mdbSidenav"
    data-cy="sidenav-end-user-profile-information"
    [width]="500"
    [right]="true"
  >
    <div class="position-relative d-flex flex-column h-100">
      <div class="d-flex justify-content-between align-items-center p-2 navbar">
        <div class="d-flex align-items-center">
          <p class="mb-0 me-1 fw-bold text-muted">Profile Information</p>
        </div>
        <button type="button" class="btn-transparent" (click)="onClose()">
          <i class="fas fa-xmark"></i>
        </button>
      </div>
      <div
        class="scrollbar-primary thin overflow-auto d-flex flex-column gap-3 p-2 text-gray-dark"
      >
        <div>
          <div class="d-flex gap-1">
            <i-bs name="person-fill" class="d-flex text-gray"></i-bs>
            <p class="mb-1 fw-bold">End User Information</p>
          </div>
          <div class="ms-3 d-flex gap-1 flex-column">
            <div class="row gx-2">
              <div class="col-6 text-gray">End User Name:</div>
              <div class="col-6">
                <span>{{ enduserName ? enduserName : 'N/A' }}</span>
              </div>
            </div>
            <div class="row gx-2">
              <div class="col-6 text-gray">Account Number in RDMS:</div>
              <div class="col-6">
                <span>{{ data && data.accountNumber ? data.accountNumber : 'N/A' }}</span>
              </div>
            </div>
            <div class="row gx-2">
              <div class="col-6 text-gray">DOB:</div>
              <div class="col-6">
                <span>{{ enduserDOB ? enduserDOB : 'N/A' }}</span>
              </div>
            </div>
            <div class="row gx-2">
              <div class="col-6 text-gray">Email:</div>
              <div class="col-6">
                <span>{{ data && data.demographic.email ? data.demographic.email : 'N/A' }}</span>
              </div>
            </div>
            <div class="row gx-2">
              <div class="col-6 text-gray">Phone:</div>
              <div class="col-6">
                <span>{{ data && data.demographic.phone ? (data.demographic.phone | formatPhoneNumber) : 'N/A' }}</span>
              </div>
            </div>
            <div class="row gx-2">
              <div class="col-6 text-gray">Address:</div>
              <div class="col-6">
                <span>{{ data && data.demographic.address.home.addressLine1 ? data.demographic.address.home.addressLine1 : 'N/A' }}{{ data && data.demographic.address.home.addressLine2 ? ', '+data.demographic.address.home.addressLine2:'' }}{{ data && data.demographic.address.home.city ? ', '+data.demographic.address.home.city:'' }}{{ data && data.demographic.address.home.state ? ', '+data.demographic.address.home.state:'' }}{{ data && data.demographic.address.home.zip ? ', '+data.demographic.address.home.zip:'' }}</span>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="d-flex gap-1">
            <i-bs name="watch" class="d-flex text-gray"></i-bs>
            <p class="mb-1 fw-bold">Device Information</p>
          </div>
          <div class="ms-3 d-flex gap-1 flex-column">
            <div class="row gx-2">
              <div class="col-6 text-gray">Device:</div>
              <div class="col-6">
                <span>{{ data && data.activeDevice.info.name ? data.activeDevice.info.name : 'N/A' }}</span>
              </div>
            </div>
            <div class="row gx-2">
              <div class="col-6 text-gray">Device Phone:</div>
              <div class="col-6">
                <span>{{ data && data.activeDevice.info.associatedPhone ? (data.activeDevice.info.associatedPhone | formatPhoneNumber) : 'N/A' }}</span>
              </div>
            </div>
            <div class="row gx-2">
              <div class="col-6 text-gray">Model Number:</div>
              <div class="col-6">
                <span>{{ data && data.activeDevice.info.modelNumber ? data.activeDevice.info.modelNumber : 'N/A' }}</span>
              </div>
            </div>
            <div class="row gx-2">
              <div class="col-6 text-gray">Serial Number:</div>
              <div class="col-6">
                <span>{{ data && data.activeDevice.info.serialNumber ? data.activeDevice.info.serialNumber : 'N/A' }}</span>
              </div>
            </div>
            <div class="row gx-2">
              <div class="col-6 text-gray">IMEI:</div>
              <div class="col-6">
                <span>{{ data && data.activeDevice.info.imeiNumber ? data.activeDevice.info.imeiNumber : 'N/A' }}</span>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="d-flex gap-1">
            <i-bs name="shield-lock-fill" class="d-flex text-gray"></i-bs>
            <p class="mb-1 fw-bold">Safety & Security Information</p>
          </div>
          <div class="ms-3 d-flex gap-1 flex-column">
            <div class="row gx-2">
              <div class="col-6 text-gray">Advocate:</div>
              <div class="col-6">
                <span>{{ data && data.advocate.name ? data.advocate.name : 'N/A' }}</span>
              </div>
            </div>
            <div class="row gx-2">
              <div class="col-6 text-gray">Safe Word:</div>
              <div class="col-6">
                <span>{{ data && data.safeWord ? data.safeWord : 'N/A' }}</span>
              </div>
            </div>
            <div class="row gx-2">
              <div class="col-6 text-gray">Aggressor’s Name:</div>
              <div class="col-6">
                <span>{{ data && data.info.nameOfPerpetrators ? data.info.nameOfPerpetrators : 'N/A' }}</span>
              </div>
            </div>
            <div class="row gx-2">
              <div class="col-6 text-gray">Aggressor’s DOB:</div>
              <div class="col-6">
                <span>{{ perpetratorDOB ? perpetratorDOB : 'N/A' }}</span>
              </div>
            </div>
            <div class="row gx-2">
              <div class="col-6 text-gray">Aggressor’s Car:</div>
              <div class="col-6">
                <span>{{ data && data.info.modelOfperpetrator ? data.info.modelOfperpetrator : 'N/A' }}{{ data && data.info.modelOfperpetrator && data.info.perpetratorLicensePlate ? ', '+data.info.perpetratorLicensePlate : '' }}</span>
              </div>
            </div>
            <div class="row gx-2">
              <div class="col-6 text-gray">Access to Firearms:</div>
              <div class="col-6">
                <span>{{ data && data.info.perpetratorOwns ? (data.info.perpetratorOwns | titlecase) : 'N/A' }}</span>
              </div>
            </div>
            <div class="row gx-2">
              <div class="col-6 text-gray">Restraining Order:</div>
              <div class="col-6">
                <span>{{ data && data.info.activeRestrainingOrder ? (data.info.activeRestrainingOrder | titlecase) : 'N/A' }}</span>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="d-flex gap-1">
            <i class="fas fa-house text-gray" style="font-size: 16px"></i>
            <p class="mb-1 fw-bold">Household Information</p>
          </div>
          <div class="ms-3 d-flex gap-1 flex-column">
            <div class="row gx-2">
              <div class="col-6 text-gray">Members:</div>
              <div class="col-6">
                <span>Adult: {{ data && data.info.householdMember.adult ? data.info.householdMember.adult : 'N/A' }}</span> <br />
                <span>Child: {{ data && data.info.householdMember.children ? data.info.householdMember.children : 'N/A' }}</span>
              </div>
            </div>
            <div class="row gx-2">
              <div class="col-6 text-gray">Type of Home:</div>
              <div class="col-6">
                <span>{{ data && data.info.typeOfResidence ? (data.info.typeOfResidence | titlecase) : 'N/A' }}</span>
              </div>
            </div>
            <div class="row gx-2">
              <div class="col-6 text-gray">Entrance Location:</div>
              <div class="col-6">
                <span>{{ data && data.info.entranceLocation ? (data.info.entranceLocation | titlecase) : 'N/A' }}</span>
              </div>
            </div>
            <div class="row gx-2">
              <div class="col-6 text-gray">Basement/Attic:</div>
              <div class="col-6">
                <span>{{ data && data.info.basementAttic ? (data.info.basementAttic | titlecase) : 'N/A' }}</span>
              </div>
            </div>
            <div class="row gx-2">
              <div class="col-6 text-gray">Firearms at Home:</div>
              <div class="col-6">
                <span>{{ data && data.info.firearmAtHome ? (data.info.firearmAtHome | titlecase) : 'N/A' }}</span>
              </div>
            </div>
            <div class="row gx-2">
              <div class="col-6 text-gray">Any Dogs:</div>
              <div class="col-6">
                <span>{{ data && data.anyDog ? (data.anyDog | titlecase) : 'N/A' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mdb-sidenav>
</mdb-sidenav-layout>
