import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { API_CONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityLogService {

  constructor(
    private http: HttpClientService
  ) { }

  getActivityLogs(query?: any) {
    return this.http.get(API_CONSTANTS.ACTIVITY_LOGS, query)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getById(id: string) {
    return this.http.get(API_CONSTANTS.ACTIVITY_LOGS + `/${id}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
