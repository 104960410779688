import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { API_CONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(
    private http: HttpClientService
  ) { }

  getAllOrganizations(query?: any) {
    return this.http.get(API_CONSTANTS.ORGANIZATIONS, query)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getOrganizationById(organizationId: any) {
    return this.http.get(API_CONSTANTS.ORGANIZATIONS + `/${organizationId}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  create(organization: any) {
    return this.http.post(API_CONSTANTS.ORGANIZATIONS, organization)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  update(organization: any) {
    return this.http.put(API_CONSTANTS.ORGANIZATIONS + `/${organization.id}`, organization)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  delete(organizationId: string) {
    return this.http.remove(API_CONSTANTS.ORGANIZATIONS + `/${organizationId}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  updateConfigurations(data: any) {
    return this.http.patch(`${API_CONSTANTS.ORGANIZATIONS}/${data.id}${API_CONSTANTS.CONFIGURATIONS}`, data.configurations).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
