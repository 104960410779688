import { Injectable } from "@angular/core";
import * as moment from 'moment';
import { NIGHTWEB_APP_CONSTANT } from "../constants";

@Injectable()

export class DateUtility {
    public pad(n: any) { return n < 10 ? "0" + n : n; }

    public formatDate(dateTime: Date, format: string = NIGHTWEB_APP_CONSTANT.defaultMomentDateFormat) {
        if (dateTime) {
            return moment(dateTime).format(format);
        }
        return null;
    }
    
    public getDate(dateTime: string): any {
        if (dateTime) {
            return new Date(moment(dateTime).format(NIGHTWEB_APP_CONSTANT.defaultDateFormat))
        }
        return null;
    }

    public getTime(dateTime: string): any {
        if (dateTime) {
            return moment(dateTime).format(NIGHTWEB_APP_CONSTANT.defaultTimeFormat)
        }
        return null;
    }

    public concatDateAndTime(date: string, time: string): any {
        let parsedDate, parsedTime;
        if (date && time) {
            parsedDate = moment(date).format(NIGHTWEB_APP_CONSTANT.defaultDateFormat);
            parsedTime = moment(time, NIGHTWEB_APP_CONSTANT.defaultDateFormat).format( NIGHTWEB_APP_CONSTANT.shorTimeFormat);
            return parsedDate + 'T' + parsedTime;
        } else if (date) {
            parsedDate = moment(date).format(NIGHTWEB_APP_CONSTANT.defaultDateFormat);
            return parsedDate;
        }
        return null;
    }

    public isDateAfter(date: any): boolean {
        return moment(date).isAfter(new Date());
    }

    public convertToMMddYYYY(date: any) {
        date = new Date(date);
        let result = [this.pad(date.getMonth() + 1), this.pad(date.getDate()), date.getFullYear()].join('/');
        return result;
      }

    public getMinDate(): Date {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate() - 1;

        return new Date(year, month, day);
    }

    public getMaxDate(): Date {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate() + 1;

        return new Date(year, month, day);
    }

    public getMinCreatedToDate(date?: Date): Date {
        if (!date)
            date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate() - 1;

        return new Date(year, month, day);
    }

    public getMaxCreatedFromDate(date?: Date): Date {
        if (!date)
            date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate() + 1;

        return new Date(year, month, day);
    }

    public checkIsBefore(from: Date, to: Date) {
        return moment(from).isBefore(to);
    }
}
