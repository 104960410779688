import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs';
import { NIGHTWEB_APP_CONSTANT, STATUS_CODES, US_STATES } from 'src/app/constants';
import { OrganizationService, ToasterService } from 'src/app/services';
import { NightwebUtil } from 'src/app/utils';

@Component({
  selector: 'app-add-edit-organization-modal',
  templateUrl: './add-edit-organization-modal.component.html',
  styleUrls: ['./add-edit-organization-modal.component.scss']
})
export class AddEditOrganizationModalComponent implements OnInit {

  organizationForm!: FormGroup;

  loading = false;
  submitted = false;

  title!: string;
  formData: any = {};
  phoneMask = NIGHTWEB_APP_CONSTANT.phoneMask;
  states = US_STATES;
  status_codes = STATUS_CODES;
  dateFormat = NIGHTWEB_APP_CONSTANT.mdbDefaultDateFormat;
  constructor(
    private formBuilder: FormBuilder,
    private nightwebUtil: NightwebUtil,
    private toasterService: ToasterService,
    private organizationService: OrganizationService,
    public modalRef: MdbModalRef<AddEditOrganizationModalComponent>
  ) {
    this.setOrganizationForm()
  }

  ngOnInit(): void {
    if (this.formData) {
      this.organizationForm.patchValue(this.formData);
    }
  }

  get address(): FormGroup {
    return this.organizationForm.get('address') as FormGroup;
  }

  get contacts(): FormArray {
    return this.organizationForm.get('contacts') as FormArray;
  }

  setOrganizationForm() {
    this.organizationForm = this.formBuilder.group({
      id: [],
      businessName: ['', Validators.required],
      sameAsBusinessName: [false],
      displayName: [''],
      contacts: this.formBuilder.array([]),
      email: ['', [Validators.required, Validators.pattern(NIGHTWEB_APP_CONSTANT.emailPattern)]],
      phone: [''],
      address: this.formBuilder.group({
        addressLine1: ['', Validators.required],
        addressLine2: [''],
        city: ['', Validators.required],
        state: ['', Validators.required],
        zip: ['', Validators.required],
      })
    });
    // this.setContacts();
  }

  setContacts() {
    this.contacts.push(this.createItem());
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      role: [''],
      name: [''],
      email: [''],
      phone: []
    });
  }

  close() {
    this.submitted = false;
    this.modalRef.close();
  }

  save() {
    if (this.organizationForm.valid) {
      this.loading = true;
      const organization = this.nightwebUtil.shallowCopy(this.organizationForm.value)
      delete organization['sameAsBusinessName'];
      
      if (this.organizationForm.get('id')?.value) {
        this.organizationService.update(organization)
          .pipe(finalize(() => { this.loading = false }))
          .subscribe({
            next: (response) => {
              if (response.statusCode == this.status_codes.OK) {
                this.toasterService.show('Success', response.message);
                this.modalRef.close(response.data);
              }
            },
            error: (e) => {
            }
          })
      }else {
        this.organizationService.create(organization)
          .pipe(finalize(() => { this.loading = false }))
          .subscribe({
            next: (response) => {
              if (response.statusCode == this.status_codes.OK) {
                this.toasterService.show('Success', response.message);
                this.modalRef.close(response.data);
              }
            },
            error: (e) => {
            }
          })
      }
    }
  }

  receiveSelectedValue(state: any) {
    this.organizationForm.get('address')?.patchValue({
      state
    })
  }

  receiveCheckEvent(event: boolean) {
    if (event) {
      this.organizationForm.patchValue({
        displayName: this.organizationForm.get('businessName')?.value
      });
    } else {
      this.organizationForm.patchValue({
        displayName: ''
      });
    }
  }

}
