<div class="row justify-content-center">
    <div class="col col-sm-8 col-md-7 col-lg-5 col-xl-6 col-xxl-4">
        <div class="d-flex justify-content-center mb-4">
            <img class="bg-image" src="../../../assets/img/png/NexionSolutionslogo-Login.png" />
        </div>
        <form [formGroup]="loginForm" autocomplete="off">
            <div class="card">
                <div class="card-body p-3">
                    <h4 class="card-title text-center">Nightweb Login</h4>

                    <div class="md-form">
                        <label for="userName" class="form-label">Username</label>
                        <div class="input-group rounded mb-2"
                            [class.is-invalid]="loginForm.controls['userName'].dirty && loginForm.get('userName')?.invalid">
                            <span class="input-group-text ps-2 pe-0 bg-gray-light"><i-bs name="person-fill"></i-bs></span>
                            <input type="text" class="form-control px-1 bg-gray-light" id="userName" formControlName="userName" />
                        </div>
                    </div>
                    <div class="md-form">
                        <label for="loginPassword" class="form-label">Password</label>
                        <div class="input-group flex-nowrap rounded me-3 mb-2"
                            [class.is-invalid]="loginForm.controls['password'].dirty && loginForm.get('password')?.invalid">
                            <div class="input-group">
                                <span class="input-group-text ps-2 pe-0 bg-gray-light"><i-bs name="lock-fill"></i-bs></span>
                                <input [type]="showLoginPassword ? 'text' : 'password'" class="form-control px-1 bg-gray-light"
                                    id="loginPassword" formControlName="password" />
                                <span (click)="showLoginPassword = !showLoginPassword"
                                    class="input-group-text px-1 cursor-pointer bg-gray-light">
                                    <i-bs class="mx-2" [name]="showLoginPassword ? 'eye' : 'eye-slash'"></i-bs>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-2 justify-content-between">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="rememberMe" formControlName="rememberMe">
                            <label class="form-check-label" for="rememberMe">
                                Keep me logged in
                            </label>
                        </div>
                        <a class="text-primary" role="button" (click)="openForgotPassword()">Forgot Password?</a>
                    </div>
                    <button type="submit" class="btn btn-block btn-primary" debounceClick
                        (debounceClick)="login()" [disabled]="loading || loginForm.invalid">
                        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"
                            *ngIf="loading"></span>
                        Login
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="row justify-content-center mt-4">
    <div class="col text-center">
        <span>Copyright © {{date | date: 'yyyy'}} Nexion. </span>
        <span>Privacy | Terms of use </span>
    </div>
</div>